import React from "react"
import CellsSection from "../../../Common/CommonSections/CellsSection"
import { StyledSectionTitle, StyledMarginWrapper } from "../../common-styles"
import {
  WE_WORK_WITH_BORDER_COLOR,
  WE_WORK_WITH_CELLS,
  WE_WORK_WITH_SECTION_TITLE,
} from "../../constants"

const WeWorkWith = () => {
  return (
    <StyledMarginWrapper>
      <StyledSectionTitle>{WE_WORK_WITH_SECTION_TITLE}</StyledSectionTitle>
      <CellsSection
        configuration={WE_WORK_WITH_CELLS}
        borderColor={WE_WORK_WITH_BORDER_COLOR}
        hasSingleElement
      />
    </StyledMarginWrapper>
  )
}

export default WeWorkWith
