import styled, { css } from "styled-components"
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants"

export const StyledCellsSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
    ${({ hasSingleElement }) => !hasSingleElement && css`
        grid-template-columns: 1fr;
        gap: 24px;
  `}
  }

  @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
    grid-template-columns: 1fr;
    gap: 24px;
  }
`

export const StyledCell = styled.div`
  display: flex;
  min-height: 10rem;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  border-radius: 1px;
  border: 1px solid ${({ borderColor }) => borderColor};
`

export const StyledCellContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 16px;
`

export const StyledTitle = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-family: "Nunito Sans";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.56px;
  margin: 0;
`

export const StyledDescription = styled.span`
  color: rgba(255, 255, 255, 0.6);
  font-family: "Nunito Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 200;
  line-height: 24px;
  letter-spacing: 0.8px;
`

export const StyledSingleElement = styled.p`
  color: #ffffff;
  text-align: center;
  font-family: "Nunito Sans";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 128.571%;
  letter-spacing: 0.56px;
`
