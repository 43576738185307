import React from "react"
import {
  StyledCellsSectionWrapper,
  StyledCell,
  StyledCellContent,
  StyledTitle,
  StyledDescription,
  StyledSingleElement,
} from "./styled"

const CellsSection = ({
  configuration,
  borderColor = "#77617D",
  hasSingleElement = false,
}) => {
  return (
    <StyledCellsSectionWrapper hasSingleElement={hasSingleElement}>
      {configuration.map(({ title, description }) => (
        <StyledCell borderColor={borderColor}>
          <StyledCellContent>
            {!hasSingleElement ? (
              <>
                <StyledTitle>{title}</StyledTitle>
                <StyledDescription>{description}</StyledDescription>
              </>
            ) : (
              <StyledSingleElement>{title}</StyledSingleElement>
            )}
          </StyledCellContent>
        </StyledCell>
      ))}
    </StyledCellsSectionWrapper>
  )
}

export default CellsSection
